import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchData } from "../../../services/api";

import ModuleCards from "../../../components/ui/Card-Components/ModuleCards/ModuleCards";
const ManageModules = () => {
  const [modules, setModules] = useState([]);

  // effects
  useEffect(() => {
    async function getModules() {
      try {
        const response = await fetchData("/modules/all");
        setModules(response.data.data);
      } catch (error) {
        if (!error.response) {
          return;
        }
      }
    }
    getModules();
  }, []);

  return (
    <div>
      <Link
        to="/admin/manage-modules/create-module"
        className="btn btn-success mb-4"
      >
        Създай модул
      </Link>
      <ModuleCards modules={modules} setModules={setModules} />
    </div>
  );
};

export default ManageModules;
