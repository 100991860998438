import { Col } from "react-bootstrap";
import Footer from "./Footer";

import RecievedMessage from "./RecievedMessage";
import SendedMessage from "./SendedMessage";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { fetchData } from "../../../../services/api";

const Messages = ({ room, sendMessage, socket }) => {
  const { user } = useSelector((state) => state.user);

  const [messages, setMessages] = useState([]);

  const [scrollTo, setScrollTo] = useState(-1);

  const chatBoxRef = useRef(null);
  const chatEndRef = useRef(null);
  const chatStartRef = useRef(null);
  const messagesRef = useRef(messages);

  const loadOlder = async (first_message_id) => {
    try {
      const response = await fetchData(
        `/chat/messages?chat_room_id=${room}&first_message_id=${first_message_id}`
      );
      if (response.status !== 201) throw new Error();
      const messages = response.data.data.sort((x) => x.id).reverse();
      setMessages(messages);
    } catch (err) {
      console.log(err.messages);
    }
  };

  async function getMessages() {
    try {
      const response = await fetchData(`/chat/messages?chat_room_id=${room}`);
      if (response.status !== 201) throw new Error();
      const messages = response.data.data.sort((x) => x.id).reverse();
      setMessages(messages);
    } catch (err) {
      console.log(err.messages);
    }
  }

  const scrollToBottom = () => {
    const chat = chatEndRef.current;
    if (!chat) return;
    chat.scrollIntoView();
  };

  const loadOlderMessages = async () => {
    const messages = messagesRef.current;
    if (Array.isArray(messages) && messages.length > 0) {
      const first_message_id = messages[0].id;
      const element = chatStartRef.current.id;
      await loadOlder(first_message_id);
      const nodes = Array.from(chatBoxRef.current.childNodes);
      for (let node of nodes) {
        if (node.id === element) {
          setScrollTo(node.id);
          break;
        }
      }
    }
  };

  const handleScroll = async (e) => {
    const element = e.target;
    if (element.scrollTop !== 0) return;
    loadOlderMessages();
  };

  useEffect(() => {
    const chatBox = chatBoxRef.current;
    chatBox.addEventListener("scroll", handleScroll);
    return () => chatBox.removeEventListener("scroll", handleScroll);
  }, [room]);

  // useEffect(() => {
  //   if(messages.length > 0 && Array.isArray(data) && data.length === 0) {
  //     return;
  //   }
  //   setMessages([...data]);
  // }, [data, chatEndRef]);

  useEffect(() => {
    messagesRef.current = messages;
    if (scrollTo !== -1) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView();
      }
      return;
    }
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    getMessages();
    socket.on("update", async (data) => {
      await getMessages();
    });
  }, [room]);

  return (
    <div className="chat-body p-2">
      <div className="messages" ref={chatBoxRef}>
        {messages &&
          messages.map((message, index) => {
            return (
              <div
                key={message.id}
                id={message.id}
                ref={
                  index === messages.length - 1
                    ? chatEndRef
                    : index === 0
                    ? chatStartRef
                    : null
                }
              >
                {message.sender === user.id ? (
                  <SendedMessage key={index} data={message} />
                ) : (
                  <RecievedMessage key={index} data={message} />
                )}
              </div>
            );
          })}
      </div>
      <Footer
        sendMessageEvent={(content) => {
          scrollToBottom();
          sendMessage(content);
        }}
      />
    </div>
  );
};

export default Messages;
