import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { fetchData } from "../../services/api";

import ModuleCards from "../../components/ui/Card-Components/ModuleCards";
import BackToTopButton from "../../components/ui/BackToTopButton";
import ModuleNotFound from "../Errors/ModuleNotFound/ModuleNotFound";
import "./Modules.scss";

const ModulesLanding = () => {
  const moduless = useSelector((state) => state.modules);
  const { user } = useSelector((state) => state.user);
  const [searchedModules, setSearchedModules] = useState(moduless.modules);
  const [modules, setModules] = useState(moduless.modules);
  const userRole = user?.role_name?.toLowerCase();

  const getModules = async () => {
    if (userRole === "admin") {
      try {
        const response = await fetchData("/modules/all");
        setModules(response.data.data);
      } catch (error) {
        if (!error.response) {
          return;
        }
      }
    }
  };

  const searchInput = useRef();

  const searchFilter = () => {
    const value = searchInput.current ? searchInput.current.value : '';

    const filteredModules = modules?.filter((module) => {
      return (
        module.module_name?.toLowerCase().includes(value.toLowerCase()) ||
        module.module_description?.toLowerCase().includes(value.toLowerCase())
      );
    });
    setSearchedModules(filteredModules);
  };

  useEffect(() => {
    getModules();
  }, []);

  useEffect(() => {
    searchFilter();
  }, [modules]);

  return (
    <>
      {modules?.length === 0 ? (
        <ModuleNotFound />
      ) : (
        <>
          <div className="modules">
            <div className="page-heading ">
              <div className="">
                <h3>Моите обучeния</h3>
              </div>
              <input
                type="text"
                className="searchInput form-control"
                ref={searchInput}
                onInput={searchFilter}
                name="search"
                placeholder="Търси"
              />
            </div>
            <ModuleCards modules={searchedModules} />
          </div>
          <BackToTopButton />
        </>
      )}
    </>
  );
};

export default ModulesLanding;